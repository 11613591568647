export default class Tabs {
	constructor({
		wrapper,
		link,
		item,
		classOpen,
		onOpen,
		onClose
	} = {}) {
		this.wrapper = wrapper || '[data-tabs="wrapper"]';
		this.link    = link || '[data-tabs="link"]';
		this.item    = item || '[data-tabs="item"]';
		this.options = {
			classActive: classOpen || 'active',
			onOpen     : onOpen || null
		};

		this._init();
	}

	_init() {
		const wrapper = document.querySelector(this.wrapper);

		if (!wrapper) return;

		wrapper.querySelectorAll(this.link).forEach(function (link) {
			link.addEventListener('click', this._click.bind(this));
		}.bind(this))
	}

	_click(e) {
		e.preventDefault();

		if (!e.target.closest(this.link)) return;
		if (e.target.classList.contains(this.options.classActive)) return;

		const target = e.target.dataset.id;
		this._change(e.target, target)

		return false;
	}

	_change(link, item) {
		const wrapper = document.querySelector(this.wrapper);
		const items   = wrapper.querySelectorAll(`${this.item}.${this.options.classActive}`);
		const links   = wrapper.querySelectorAll(`${this.link}.${this.options.classActive}`);
		[].forEach.call(items, el => el.classList.remove(this.options.classActive));
		[].forEach.call(links, el => el.classList.remove(this.options.classActive));

		link.classList.add(this.options.classActive);
		document.querySelector(item).classList.add(this.options.classActive);
	}
}

