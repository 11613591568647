import MenuHighlighter from './components/MenuHighlighter';
import DropPanel       from './components/DropPanel';
import MainMenu        from './components/MainMenu';
import Tabs            from './components/Tabs';
import Cookies         from 'js-cookie';
import Marquee3k       from 'marquee3000';

function setViewportProperty(doc) {
	let prevClientHeight;
	let customVar = '--' + 'vh';

	function handleResize() {
		let clientHeight = doc.clientHeight;
		if (clientHeight === prevClientHeight) return;
		requestAnimationFrame(function updateViewportHeight() {
			doc.style.setProperty(customVar, (clientHeight * 0.01) + 'px');
			prevClientHeight = clientHeight;
		});
	}

	handleResize();
	return handleResize;
}

/**
 * Function that measures the size of the footer and adds an indent for the whole page
 * @param el {HTMLElement}
 */
function footerMargin(el) {
	if (!el) return;

	const breakpoint  = window.matchMedia('(min-width :1081px)');
	const pageWrapper = document.querySelector('.page-wrapper');
	let footerSize;
	const throttle    = function (type, name, obj) {
		obj         = obj || window;
		let running = false;
		const func  = function () {
			if (running) { return; }
			running = true;
			requestAnimationFrame(function () {
				obj.dispatchEvent(new CustomEvent(name));
				running = false;
			});
		};
		obj.addEventListener(type, func);
	};

	const marginPage = () => {
		if (breakpoint.matches === true) {
			footerSize                     = el.offsetHeight + 30 + 'px';
			pageWrapper.style.marginBottom = footerSize;
		} else {
			if (footerSize) {
				footerSize                     = '0px';
				pageWrapper.style.marginBottom = footerSize;
			}
		}
	}

	throttle('resize', 'optimizedResize');

	marginPage();
	window.addEventListener('optimizedResize', marginPage);
}

/**
 * Ticker activation function
 * @param el {string}
 * @param container {string}
 */
function ticker(el, container) {
	if (!document.querySelector(el)) return;
	Marquee3k.init();

	const options = {
		rootMargin: '0px',
		threshold : 0
	}
	const cb      = function (entries, observer) {
		const entry = entries[0];
		(entry.isIntersecting) ? Marquee3k.playAll() : Marquee3k.pauseAll();
	};

	const observer = new IntersectionObserver(cb, options);
	observer.observe(document.querySelector(container));
}

function tabs() {

}

// Init
function init() {
	function cookiePanel({
		container,
		button
	}) {
		const link = document.querySelector(button);
		if (!link) return;

		function hideCookiePanel(e) {
			e.preventDefault();
			e.target.closest(container).remove();
			Cookies.set('cookie_panel', true, {expires: 365});
			link.removeEventListener('click', hideCookiePanel);
			return false;
		}

		link.addEventListener('click', hideCookiePanel);
	}

	new MenuHighlighter();
	new MenuHighlighter('[data-news-highlighter]', {
		el: '.news-item'
	});
	new DropPanel();
	new MainMenu();
	cookiePanel({
		container: '#cookiePanel',
		button   : '#cookiePanelTrigger'
	});
	
	new Tabs();

	window.addEventListener('resize', setViewportProperty(document.documentElement));

	ticker('.marquee3k', '.section-ticker');

	footerMargin(document.querySelector('.footer'));
}

document.addEventListener('DOMContentLoaded', function (event) {
	init();
});

