/**
 * Highlight class of the menu item that is not active
 */
export default class MenuHighlighter {
	constructor(target = '[data-menu-highlighter]', {
		el,
		classHighlighter
	}                  = {}) {
		this.target           = target;
		this.el               = el || 'li';
		this.classHighlighter = classHighlighter || 'is-highlight';
		this.isTouch          = 'ontouchstart' in window || window.DocumentTouch && document instanceof window.DocumentTouch || navigator.maxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0;
		this.init();
	}

	init() {
		if (this.isTouch) return;

		const wrapper = document.querySelector(this.target);

		if (!wrapper) return;
		wrapper.addEventListener('mouseover', this._enter.bind(this));
		wrapper.addEventListener('mouseout', this._leave.bind(this));
	}

	_enter(e) {
		const traget = e.target.closest(this.el);
		if (!traget) return;

		this._addHighlighter(document.querySelector(this.target).querySelectorAll(this.el), traget)
	}

	_leave() {
		this._removeHighlighter(document.querySelector(this.target).querySelectorAll(this.el))
	}

	_addHighlighter(arr, skip) {
		arr.forEach(arr => {
			if (arr === skip) return;

			arr.classList.add(this.classHighlighter);
		})
	}

	_removeHighlighter(arr) {
		arr.forEach(arr => arr.classList.remove(this.classHighlighter));
	}
}

